import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useEffect } from "react";

const AuthHandler = () => {
  const { instance, accounts } = useMsal();

  useEffect(() => {
    const getToken = async () => {
      try {
        if (accounts.length > 0) {
          const tokenResponse = await instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0],
          });

          localStorage.setItem("token", tokenResponse.accessToken);
        }
      } catch (error) {
        console.error("Error acquiring token:", error);
      }
    };

    getToken();
  }, [instance, accounts]);

  return null; // This component only handles authentication logic
};

export default AuthHandler;
